import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private toastrService: ToastrService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((errorResponse: any) => {
          const errorMessage = errorResponse.status === 500 || errorResponse.status === 0 ?
            'Something went wrong! Please try again later' :
            errorResponse.error.message;
  
          this.toastrService.error(errorMessage, undefined, { positionClass: 'toast-custom-bottom-center' });

          return of(errorResponse);
        })
      );
  }
}
