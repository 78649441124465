import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

import { SubmissionsListComponent } from "./submissions-list.component";
import { CardModule } from "../card/card.module";
import { TableModule } from "../table/table.module";
import { DateInputModule } from "../date-input/date-input.module";

@NgModule({
  declarations: [SubmissionsListComponent],
  exports: [SubmissionsListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    CardModule,
    TableModule,
    DateInputModule
  ]
})
export class SubmissionsListModule {}
