import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { HasRoleModule } from './directives/has-role/has-role.module';
import { BackdropModule } from './components/backdrop/backdrop.module';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.component.module';
import { ButtonModule } from './components/button/button.module';
import { CardModule } from './components/card/card.module';
import { TableModule } from './components/table/table.module';
import { DateInputModule } from './components/date-input/date-input.module';
import { ModalModule } from './components/modal/modal.module';
import { LoggedUserModule } from './components/logged-user/logged-user.module';
import { SubmissionsListModule } from './components/submissions-list/submissions-list.module';
import { SubmissionDetailsModule } from './components/submission-details/submission-details.module';
import { SubmissionsChartModule } from './components/submissions-chart/submissions-chart.module';
import { UploadDocumentsModule } from './components/upload-documents/upload-documents.module';
import { FormatPhraseModule } from './pipes/format-phrase/format-phrase.module';

@NgModule({
  exports: [
    HasRoleModule,
    BackdropModule,
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    TableModule,
    DateInputModule,
    ModalModule,
    LoggedUserModule,
    SubmissionsListModule,
    SubmissionDetailsModule,
    SubmissionsChartModule,
    UploadDocumentsModule,
    FormatPhraseModule
  ]
})
export class SharedModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/calendar.svg'));
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/upload.svg'));
    iconRegistry.addSvgIcon('file', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/file.svg'));
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/trash.svg'));
  }
}
