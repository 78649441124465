import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SubmissionDetailsTabs {
  UserInformation = 'User Information',
}

@Injectable({ providedIn: 'root' })
export class CoreService {
  private isLoadingSource = new BehaviorSubject<boolean>(false);
  private submissionDetailsCurrentTabSource = new BehaviorSubject<SubmissionDetailsTabs>(SubmissionDetailsTabs.UserInformation);
  public isLoading$ = this.isLoadingSource.asObservable();
  public submissionDetailsCurrentTab$ = this.submissionDetailsCurrentTabSource.asObservable();

  public setIsLoading(isLoading: boolean): void {
    this.isLoadingSource.next(isLoading);
  }

  public setSubmissionDetailsCurrentTab(tab: SubmissionDetailsTabs): void {
    this.submissionDetailsCurrentTabSource.next(tab);
  }
}
