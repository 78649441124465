import { Injectable } from "@angular/core";
import { Auth, Hub } from 'aws-amplify';
import { AuthenticatorService } from "@aws-amplify/ui-angular";

import { AdminChangePasswordPayload, AuthenticatedUser, UpdateUserPayload } from "../types/auth.types";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loggedUser: AuthenticatedUser;

  constructor(
    public authenticator: AuthenticatorService
  ) {
    this.loggedUser = this.authenticator.user as AuthenticatedUser;

    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        this.loggedUser = data.payload.data;

        if (!this.loggedUser.attributes) {
          this.loggedUser.attributes = data.payload.data.challengeParam.userAttributes;
        }
      }
    });
  }

  public async getJwtToken(): Promise<string> {
    return Auth.currentSession().then((session) => session.getIdToken().getJwtToken());
  }

  public getLoggedUser(): AuthenticatedUser {
    return this.loggedUser;
  }

  public logout(): void {
    this.authenticator.signOut();
  }

  public updateUser(updateUserPayload: UpdateUserPayload): Observable<void> {
    const subject = new Subject<void>();

    Auth
      .updateUserAttributes(this.authenticator.user, updateUserPayload)
      .then(async () => {
        if (updateUserPayload.name) {
          this.loggedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        }

        subject.next();
      })
      .catch((error) => {
        subject.error(error.message);
      })
      .finally(() => {
        subject.complete();
      });

    return subject;
  }

  public changePassword(data: AdminChangePasswordPayload): Promise<"SUCCESS"> {
    return Auth.changePassword(this.getLoggedUser(), data.oldPassword, data.newPassword)
  }

  public verifyUserAttribute(attribute: string, code: string): Observable<void> {
    const subject = new Subject<void>();

     Auth
      .verifyCurrentUserAttributeSubmit(attribute, code)
      .then(async () => {
        this.loggedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        subject.next();
      })
      .catch((error) => {
        subject.error(error.message);
      })
      .finally(() => {
        subject.complete();
      });

     return subject;
  }
}
