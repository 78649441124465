import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

import {
  ChartContext,
  GetDownloadSubmissionsParams,
  GetSubmissionsParams,
  GetSubmissionsResponse,
  Submission,
  ChartResponse,
} from "../types/submission.types";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class SubmissionService {
  private apiUrl = `${environment.apiUrl}/submissions`;

  constructor(private http: HttpClient) {}

  public getSubmissions({
    campaign,
    page,
    pageSize,
    orderByColumn,
    orderBy,
    search
  }: GetSubmissionsParams): Observable<GetSubmissionsResponse> {
    let params = new HttpParams()
      .set('page', page || 0)
      .set('pageSize', pageSize || 10)
      .set('orderBy', orderBy || 'DESC')
      .set('orderByColumn', orderByColumn || '_id');

    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<GetSubmissionsResponse>(`${this.apiUrl}/${campaign}`, { params });
  }

  public getSubmission(_id: string, campaign: string) {
    return this.http.get<Submission>(`${this.apiUrl}/${campaign}/${_id}`);
  }

  public downloadFile(submissionParams: GetDownloadSubmissionsParams, campaign: string): Observable<Blob> {
    const params = new HttpParams({
      fromObject: { ...submissionParams }
    });
    return this.http.get<Blob>(`${this.apiUrl}/${campaign}/download`, { params, responseType: 'blob' as 'json' }); 
  }

  public getChartData(context: ChartContext): Observable<ChartResponse> {
    const params = new HttpParams().set('context', context)
    return this.http.get<ChartResponse>(`${this.apiUrl}/chart`, { params });
  }
}
