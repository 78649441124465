import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";

import { CoreService } from "../../../../core/services/core.service";
import { Submission } from "../../../../core/types/submission.types";

@Component({
  selector: 'app-consumer-information-tab',
  templateUrl: './consumer-information-tab.component.html',
  styleUrls: ['./consumer-information-tab.component.scss']
})
export class ConsumerInformationTabComponent {
  @Input() public submission?: Submission;
  public isLoading: Observable<boolean>;

  constructor(private coreService: CoreService) {
    this.isLoading = this.coreService.isLoading$;
  }
}
