import { Component, Inject, TemplateRef } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface ModalData {
  title?: string;
  contentTemplate?: TemplateRef<any>;
  actionsTemplate?: TemplateRef<any>;
  context: any;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public modalData: ModalData) {}
}
