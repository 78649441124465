<amplify-authenticator class="w-100" [hideSignUp]="true">
  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <img src="../../../../assets/logos/kuber-logo.png" alt="kuber logo" />
    </div>
  </ng-template>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
  >
    <router-outlet></router-outlet>

    <app-backdrop *ngIf="isLoading | async">
      <div class="h-100 d-flex justify-content-center align-items-center">
        <mat-spinner color="primary" diameter="60"></mat-spinner>
      </div>
    </app-backdrop>    
  </ng-template>
  <ng-template amplifySlot="footer">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <p class="amplify-text" style="color: var(--amplify-colors-neutral-80)">
        © 2016-2023 Kuber Financial LLC. All rights reserved.
      </p>
    </div>
  </ng-template>
</amplify-authenticator>