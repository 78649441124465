<div class="d-flex align-items-center">
  <mat-icon>lens</mat-icon>
  <span class="px-3 d-none d-sm-block">{{ loggedUser.attributes['name'] }}</span>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="handleSettingsButtonClick()">
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item (click)="handleLogoutButtonClick()">
      <mat-icon>input</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</div>

<ng-container>
  <ng-template #settingsModalContentTemplate>
      <mat-tab-group (selectedTabChange)="handleTabChange($event)">
        <mat-tab label="Profile">
          <form [formGroup]="profileForm">
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error>
                {{ getErrorMessage(profileForm, 'name', 'Name') }}
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab label="Email">
          <form
            *ngIf="currentEmailForm === emailForm.controls.address"
            [formGroup]="emailForm.controls.address"
          >
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-error>
                {{ getErrorMessage(emailForm.controls.address, 'email', 'Email') }}
              </mat-error>
            </mat-form-field>
          </form>
          <form
            *ngIf="currentEmailForm === emailForm.controls.validation"
            [formGroup]="emailForm.controls.validation"
          >
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>Code</mat-label>
              <input matInput formControlName="code" />
              <mat-error>
                {{ getErrorMessage(emailForm.controls.validation, 'code', 'Code') }}
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
        <mat-tab label="Security">
          <form [formGroup]="securityForm">
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>Old Password</mat-label>
              <input
                matInput
                formControlName="oldPassword"
                [type]="hidePasswordInputsMap.get('oldPassword') ? 'password' : 'text'"
              />
              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="togglePasswordInput($event, 'oldPassword')"
              >
                <mat-icon>
                  {{ hidePasswordInputsMap.get('oldPassword') ? 'visibility' : 'visibility_off' }}
                </mat-icon>
              </button>
              <mat-error
                [innerHTML]="getErrorMessage(securityForm, 'oldPassword', 'Old Password')"
              >
              </mat-error>
            </mat-form-field>
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>New Password</mat-label>
              <input
                matInput
                formControlName="newPassword"
                [type]="hidePasswordInputsMap.get('newPassword') ? 'password' : 'text'"
              />
              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="togglePasswordInput($event, 'newPassword')"
              >
                <mat-icon>
                  {{ hidePasswordInputsMap.get('newPassword') ? 'visibility' : 'visibility_off' }}
                </mat-icon>
              </button>
              <mat-error
                [innerHTML]="getErrorMessage(securityForm, 'newPassword', 'New Password')"
              >
              </mat-error>
            </mat-form-field>
            <mat-form-field hideRequiredMarker appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input
                matInput
                formControlName="confirmPassword"
                [type]="hidePasswordInputsMap.get('confirmPassword') ? 'password' : 'text'"
              />
              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="togglePasswordInput($event, 'confirmPassword')"
              >
                <mat-icon>
                  {{ hidePasswordInputsMap.get('confirmPassword') ? 'visibility' : 'visibility_off' }}
                </mat-icon>
              </button>
              <mat-error
                [innerHTML]="getErrorMessage(securityForm, 'confirmPassword', 'Confirm Password')"
              >
              </mat-error>
            </mat-form-field>
          </form>
        </mat-tab>
      </mat-tab-group>
  </ng-template>
  <ng-template #settingsModalActionsTemplate>
    <div class="
      d-flex
      align-items-center
      gap-3
      justify-content-end
    ">
      <button
        mat-button
        color="primary"
        (click)="handleModalCancelButtonClick()"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="disableContinueButton"
        (click)="handleModalConfirmButtonClick()"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</ng-container>