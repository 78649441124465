import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgChartsModule } from "ng2-charts";

import { SubmissionsChartComponent } from "./submissions-chart.component";

@NgModule({
  declarations: [SubmissionsChartComponent],
  exports: [SubmissionsChartComponent],
  imports: [CommonModule, NgChartsModule]
})
export class SubmissionsChartModule {}
