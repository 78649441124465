<div class="container">
  <app-card class="
    d-flex
    flex-column
    h-100
  ">
    <div class="
      flex-column
      flex-sm-row
      card-header
    ">
      <h2>
        Lead Details
      </h2>
    </div>
    <div class="tabs">
      <ng-container *ngFor="let tab of tabs | keyvalue: keepTabsOrder">
        <button
          [id]="tab.value.id"
          class="tab"
          [class.active-tab]="tab.value.name === currentTab"
          (click)="handleTabClick(tab.value.name, $event.target)"
        >
          {{ tab.value.name }}
        </button>
      </ng-container>
    </div>
    <div class="tab-content">
      <app-consumer-information-tab
        *ngIf="currentTab == tabsNames.UserInformation"
        [submission]="submission"
      ></app-consumer-information-tab>
    </div>
  </app-card>
</div>
