<span class="mt-3" *ngIf="!(isLoading | async) && !submission">
  No data received
</span>

<ng-container *ngIf="submission">
  <div class="data-container flex-column flex-sm-row gap-sm-15">
    <span class="data-label">ID</span>
    <span class="data-value">{{ submission._id }}</span>
  </div>
  <div class="data-container flex-column flex-sm-row gap-sm-15">
    <span class="data-label">Source</span>
    <span class="data-value">{{ submission.dataSource || 'Landing Page' }}</span>
  </div>
  <div *ngIf="submission.digitalLeadsSupplier" class="data-container flex-column flex-sm-row gap-sm-15">
    <span class="data-label">Supplier</span>
    <span class="data-value">{{ submission.digitalLeadsSupplier.name }}</span>
  </div>
  <ng-container *ngIf="submission.refCode">
    <div
      class="
        data-container
        flex-column
        flex-sm-row
        gap-sm-15
      "
    >
      <span class="data-label">Campaign Code</span>
      <span>{{ submission.refCode }}</span>
    </div>
  </ng-container>
  <div
    class="
      data-container
      flex-column
      flex-sm-row
      gap-sm-15
    "
  >
    <span class="data-label">Affiliate</span>
    <span>{{ submission.landingPage.affiliate.name }}</span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15
  ">
    <span class="data-label">Requested Amount</span>
    <span>
      {{ submission.loanAmount | currency:'USD':'symbol':'1.2-2' }}
    </span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15"
  >
    <span class="data-label">Name</span>
    <span>
      {{ submission.firstName }} {{ submission.lastName }}
    </span>
  </div>
  <div class="
    data-container 
    flex-column 
    flex-sm-row 
    gap-sm-15"
  >
    <span class="data-label">Suffix</span>
    <span class="text-break">{{ submission.suffix || 'N/A' }}</span>
  </div>  
  <div class="
    data-container 
    flex-column 
    flex-sm-row 
    gap-sm-15"
  >
    <span class="data-label">Email Address</span>
    <span class="text-break">{{ submission.email }}</span>
  </div>
  <div class="
    data-container 
    flex-column 
    flex-sm-row
    gap-sm-15"
  >
    <span class="data-label">Phone</span>
    <span class="text-break">{{ submission.phone ? (submission.phone | mask:'(000) 000-0000') : 'N/A' }}</span>
  </div>  
  <div class="
    data-container 
    flex-column 
    flex-sm-row
    gap-sm-15"
  >
    <span class="data-label">Mobile Phone</span>
    <span class="text-break">{{ submission.cellPhone | mask:'(000) 000-0000' }}</span>
  </div>
  <div class="
    data-container 
    flex-column 
    flex-sm-row 
    gap-sm-15"
  >
    <span class="data-label">Social Security</span>
    <span class="text-break">{{ submission.socialSecurityNumber || 'N/A' }}</span>
  </div>
  <div class="
    data-container
    flex-column
    flex-sm-row
    gap-sm-15
  ">
    <div class="data-label">
      <span>Address</span>
    </div>
    <div class="d-flex flex-column gap-2">
      <span>{{ submission.address }}</span>
      <span>{{ submission.city }}</span>
      <span>{{ submission.zipCode }}</span>
      <span>{{ submission.state }}</span>
    </div>
  </div>
  <div 
    *ngIf="submission.dateOfBirth" 
    class="
      data-container
      flex-column
      flex-sm-row
      gap-sm-15
    ">
    <span class="data-label">Date of Birth</span>
    <span>{{ submission.dateOfBirth | date:'MM/dd/YYYY' }}</span>
  </div>
  <div
    class="
      data-container
      flex-column
      flex-sm-row
      gap-sm-15
    "
  >
    <span class="data-label">Registered Date</span>
    <span>{{ submission.createdAt | date:'MM/dd/YYYY' }}</span>
  </div>
  <div 
    *ngIf="submission.monthlyIncome"
    class="
      data-container 
      flex-column 
      flex-sm-row 
      gap-sm-10 
      gap-md-13 
      gap-lg-15
    "
  >
    <span class="data-label">Monthly Income</span>
    <span>
      {{ submission.monthlyIncome | currency:'USD':'symbol':'1.2-2' }}
    </span>
  </div>
</ng-container>