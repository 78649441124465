import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

import { AffiliateComponent } from "./affiliate.component";
import { AffiliateSubmissionsComponent } from "./submissions/affiliate-submissions.component";
import { SubmissionDetailsComponent } from "../../shared/components/submission-details/submission-details.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'submissions'
  },
  {
    path: '',
    component: AffiliateComponent,
    children: [
      {
        path: 'submissions',
        pathMatch: 'full',
        component: AffiliateSubmissionsComponent
      },
      {
        path: 'submissions/details/:id',
        component: SubmissionDetailsComponent
      }
    ]
  }
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class AffiliateRoutingModule {}
