import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPhrase' })
export class FormatPhrasePipe implements PipeTransform {
  public transform(word: string): string {
    let newWord = "";
    for (let i = 0; i < word.length; i++) {
      const char = word[i];
      if (i > 0 && char.toUpperCase() === char) {
        newWord += " ";
      }
      newWord += char;
    }
    return newWord;
  }
}
