import { Component, OnInit } from "@angular/core";
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { Observable, finalize } from "rxjs";
import { format } from 'date-fns';
import 'chartjs-adapter-date-fns';

import { CoreService } from "../../../core/services/core.service";
import { FormatPhrasePipe } from "../../pipes/format-phrase/format-phrase.pipe";
import { SubmissionService } from "../../../core/services/submission.service";
import { ChartContext, ChartResponse } from "../../../core/types/submission.types";

@Component({
  selector: 'app-submissions-chart',
  templateUrl: './submissions-chart.component.html',
  styleUrls: ['./submissions-chart.component.scss'],
  providers: [FormatPhrasePipe]
})
export class SubmissionsChartComponent implements OnInit {
  public campaigns: string[];
  public campaignColors = [
    "#FF5733",
    "#00C853",
    "#FFD700",
    "#4A90E2",
    "#FF6E40",
    "#6F2DA8",
    "#E74C3C",
    "#3498DB",
    "#F1C40F",
    "#FF4500",
    "#2ECC71",
    "#D35400",
    "#9B59B6",
    "#FF0097",
    "#34495E"
  ];
  public chartData: ChartConfiguration<'line'>['data'];
  public chartOptions: ChartOptions<'line'> = {
    clip: false,
    elements: {
      line: {
        borderColor: (ctx) => {
          if (ctx.chart.chartArea) {
            const gradient = ctx.chart.ctx.createLinearGradient(0, ctx.chart.chartArea.bottom, 0, ctx.chart.chartArea.top);

            gradient.addColorStop(0, '#2F348B');
            gradient.addColorStop(0.8, '#39BCED');
  
            return gradient;
          } else {
            return;
          }
        },
        tension: 0.7
      },
      point: {
        borderWidth: 3,
        hoverBackgroundColor: '#FFF',
        hoverBorderColor: '#83bc41',
        hoverBorderWidth: 3,
        hoverRadius: 14,
        radius: 0
      }
    },
    hover: {
      intersect: false
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: (ctx) => {
          if (ctx.chart.chartArea) {
            const gradient = ctx.chart.ctx.createLinearGradient(0, ctx.chart.chartArea.bottom, 0, ctx.chart.chartArea.top);

            gradient.addColorStop(0, '#83bc41');
            gradient.addColorStop(0.8, '#3e943e');

            return gradient;
          }
          return;
        },
        displayColors: false,
        intersect: false,
        callbacks: {
          title: ([ctx]) => {
            return format(ctx.parsed.x, 'MMMM/yyyy');
          },
          label: (ctx) => {
            const sentence = this.campaigns[ctx.datasetIndex];
            const campaign = sentence.replace(sentence[0], sentence[0].toUpperCase())
            return `${ctx.parsed.y} - ${this.formatPhrasePipe.transform(campaign)}`
          }
        }
      }
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
          // drawBorder: false
        },
        ticks: {
          maxTicksLimit: 31,
          padding: 16
        },
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM'
          }
        },
        type: 'time'
      },
      y: {
        grid: {
          display: false,
          // drawBorder: false
        },
        min: 0,
        ticks: {
          format: {
            notation: 'compact'
          },
          maxTicksLimit: 6,
          padding: 16,
          stepSize: 1
        }
      }
    }
  };
  public isLoading: Observable<boolean>;

  constructor(
    private submissionService: SubmissionService, 
    private coreService: CoreService,
    private formatPhrasePipe: FormatPhrasePipe
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getDashboardTotalSubmissions();
    });
  }

  private getDashboardTotalSubmissions(): void {
    this.coreService.setIsLoading(true);
    this.submissionService
      .getChartData(ChartContext.Count)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((dashboardTotalApplications) => {
        this.setupChartData(dashboardTotalApplications);
      });
  }

  private setupChartData(dashboardTotalSubmissions?: ChartResponse): void {
    if (dashboardTotalSubmissions) {
      const numberOfSubmissionsGroupedArray: Array<Map<string, number>> = [];
      const currentDate = new Date();
      const currentYear = String(currentDate.getFullYear());
      const numberOfMonthsInYear = 12;
  
      this.campaigns = Object.keys(dashboardTotalSubmissions!)
  
      for (let index = 1; index <= this.campaigns.length; index++) {
        const numberOfSubmissionsGroupedByMonthMap = new Map<string, number>([]);
        const campaign = dashboardTotalSubmissions?.[this.campaigns[index - 1]];
        const currentYearnumberOfSubmissionsByMonthAndDays = campaign?.[currentYear];
  
        for (let index = 1; index <= numberOfMonthsInYear; index++) {
          const month = String(index);
          const numberOfSubmissionsOfTheMonth = currentYearnumberOfSubmissionsByMonthAndDays?.[month];
    
          if (numberOfSubmissionsOfTheMonth) {
            for (const day in numberOfSubmissionsOfTheMonth) {
              const numberOfSubmissionsOfTheDay = numberOfSubmissionsOfTheMonth[day];
              const numberOfSubmissionsAlreadyGroupedByMonth = numberOfSubmissionsGroupedByMonthMap.get(month) || 0;
              const numberOfSubmissionsToBeAdded = numberOfSubmissionsAlreadyGroupedByMonth + numberOfSubmissionsOfTheDay;
    
              numberOfSubmissionsGroupedByMonthMap.set(month, numberOfSubmissionsToBeAdded);
            }
          } else {
            numberOfSubmissionsGroupedByMonthMap.set(month, 0);
          }
        }
        numberOfSubmissionsGroupedArray.push(numberOfSubmissionsGroupedByMonthMap);
      }
  
      this.chartData = { datasets: [] };
  
      let campaignsSum = 0;
      numberOfSubmissionsGroupedArray.forEach((campaign) => {
        this.chartData.datasets.push({ data: [] })
  
        campaign.forEach((numberOfSubmissions, month) => {
          const monthDate = new Date(Number(currentYear), (Number(month) - 1));
    
          this.chartData.datasets[campaignsSum].borderColor = this.campaignColors[campaignsSum];
          this.chartData.datasets[campaignsSum].pointBackgroundColor = '#FFF'
          this.chartData.datasets[campaignsSum].data.push({
            x: monthDate,
            y: numberOfSubmissions
          } as any);
        });
        campaignsSum++
      });
    }
  }
}
