<ng-container *ngIf="campaign">
  <div class="container">
    <app-card class="p-4 mb-4">
      <h2 class="mb-4">Leads</h2>
      <app-submissions-chart></app-submissions-chart>
    </app-card>
  </div>
  <app-submissions-list
    tableHeader="Leads"
    [campaign]="campaign!"
  ></app-submissions-list>
</ng-container>

<span class="mt-3" *ngIf="!(isLoading | async) && campaign === null">
  No data received
</span>