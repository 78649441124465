import { AbstractControl, ValidatorFn } from '@angular/forms';

interface Rule {
  rule: boolean;
  errorMessage: string
}

interface PasswordValidationRules {
  minLength: Rule;
  hasCharacters: Rule;
  hasDigits: Rule;
  hasUpperCase: Rule;
  hasLowerCase: Rule;
  hasSpecial: Rule;
  notEntirelyNumeric: Rule;
  matchPasswords?: Rule;
}

export function passwordValidator(matchPasswordRule?: boolean, matchPasswordField?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const password = control.value;
    const rules: PasswordValidationRules = {
      minLength: { rule: password.length >= 8, errorMessage: 'Password must be at least 8 characters long' },
      hasCharacters: { rule:  /[a-zA-Z]/.test(password), errorMessage: 'Password must contain at least one letter' },
      hasDigits: { rule: /\d/.test(password), errorMessage: 'Password must contain at least one digit' },
      hasUpperCase: { rule: /[A-Z]/.test(password), errorMessage: 'Password must contain at least one uppercase letter' },
      hasLowerCase: { rule: /[a-z]/.test(password), errorMessage: 'Password must contain at least one lowercase letter' },
      hasSpecial: { rule: /[^a-zA-Z\d]/.test(password), errorMessage: 'Password must contain at least one special character' },
      notEntirelyNumeric: { rule: !/^\d+$/.test(password), errorMessage: 'Password cannot consist entirely of numbers' }
    };

    if (matchPasswordRule && matchPasswordField && control.parent) {
      const controlToMatch = (control.parent.controls as { [key: string]: AbstractControl })[matchPasswordField];

      rules.matchPasswords = {
        rule: password === controlToMatch.value,
        errorMessage: 'Passwords must match'
      };
    }

    const arrayOfMatchRules = Object.entries(rules)
      .filter(([, ruleResult]) => !ruleResult.rule)
      .map(([, ruleResult]) => ruleResult.errorMessage);
    const hasError = Object.keys(rules).some((key: string) => {
      const rule = rules[key as keyof PasswordValidationRules];
      
      if (rule && !rule.rule) {
        return true;
      }
      return false;
    });

    return hasError ? { passwordInvalid: arrayOfMatchRules } : null;
  };
}
