<div class="table-responsive">
  <mat-table
    matSort
    matSortActive
    [dataSource]="dataSource"
    (matSortChange)="handleOnSortClick($event)"
  >
    <ng-container
      *ngFor="let columnHeader of columnsHeadersMap | keyvalue"
      [matColumnDef]="columnHeader.key"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="isSortingDisabled(columnHeader.key)"
      >
        <span>{{ columnHeader.value }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [innerHTML]="element[columnHeader.key]"></span>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>
        <span>Active</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="element.active"
          (change)="handleToggleClick($event, element)"
        ></mat-slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          *ngIf="element.actions.delete"
          mat-icon-button
          color="warn"
          (click)="handleDeleteButtonClick($event, element)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          *ngIf="element.actions.download"
          mat-icon-button
          color="primary"
          (click)="handleDownloadButtonClick($event, element)"
        >
          <mat-icon>download</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="getColumnNames()"></mat-header-row>
    <mat-row
      *matRowDef="let row, columns getColumnNames()"
      [class.clickable-row]="clickableRow"
      (click)="handleRowClick($event, row)"
    ></mat-row>
  </mat-table>
  <mat-paginator
    *ngIf="!!paginatorConfig"
    aria-label="Select page"
    [length]="paginatorConfig.length"
    [pageSize]="paginatorConfig.pageSize"
    [showFirstLastButtons]="paginatorConfig.showFirstLastButtons"
    [pageSizeOptions]="paginatorConfig.pageSizeOptions"
    [pageIndex]="paginatorConfig.pageIndex"
    (page)="handleOnPageClick($event)"
  >
  </mat-paginator>
</div>