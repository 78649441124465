import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { AuthService } from "../../../core/services/auth.service";
import { AffiliateService } from "../../../core/services/affiliate.service";
import { CoreService } from "../../../core/services/core.service";

@Component({
  selector: 'app-affiliate-submissions',
  templateUrl: './affiliate-submissions.component.html',
  styleUrls: ['/affiliate-submissions.component.scss']
})
export class AffiliateSubmissionsComponent implements OnInit {
  public campaign?: string | null;
  public isLoading: Observable<boolean>;

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private affiliatesService: AffiliateService
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    const user = this.authService.getLoggedUser();

    if (user.attributes["custom:affiliate"]) {
      this.affiliatesService
      .findAffiliate(user.attributes["custom:affiliate"])
      .subscribe((affiliate) => {
        this.campaign = affiliate.name.toLowerCase() || null;
      });
    }
  }
}
