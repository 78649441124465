import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";

import { catchError, from, lastValueFrom, Observable, of, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    return from(this.handle(req, next))
      .pipe(
        catchError((errorResponse) => {
          if (errorResponse.status === 403) {
            this.router.navigate(['/auth']);

            return of(errorResponse);
          } else {
            return throwError(() => errorResponse);
          }
        })
      );
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await this.authService.getJwtToken();

    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    })

    return await lastValueFrom(next.handle(authReq));
  }
}
