import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";

import { AffiliateComponent } from "./affiliate.component";
import { AffiliateRoutingModule } from "./affiliate-routing.module";
import { SharedModule } from "../../shared/shared.module";
import { AffiliateSubmissionsComponent } from "./submissions/affiliate-submissions.component";

@NgModule({
  declarations: [AffiliateComponent, AffiliateSubmissionsComponent],
  imports: [CommonModule, RouterModule, MatFormFieldModule, AffiliateRoutingModule, SharedModule]
})
export class AffiliateModule {}
