import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { AuthService } from "src/app/core/services/auth.service";
import { Roles } from "../../core/types/role.type";

@Component({ template: '' })
export class AuthComponent implements OnInit {
  constructor (
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    const user = this.authService.getLoggedUser();
    let redirectTo = '/auth';

    if (user.attributes["custom:role"]) {
      switch(user.attributes["custom:role"]) {
        case Roles.Admin:
        case Roles.SuperAdmin:
        case Roles.CustomerService:
          redirectTo = '/admin';
          break;
        case Roles.Affiliate:
          redirectTo = '/affiliate';
          break;
        default:
          break;
      }
    } else {
      this.authService.logout();
      this.toastrService.error(
        'User not set up',
        undefined,
        { positionClass: 'toast-custom-bottom-center' }
      );
    }

    this.router.navigate([redirectTo]);
  }
}
