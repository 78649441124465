import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { DateInputComponent } from "./date-input.component";

@NgModule({
  declarations: [DateInputComponent],
  exports: [DateInputComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    BsDatepickerModule.forRoot()
  ]
})
export class DateInputModule {}
