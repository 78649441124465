export interface Role {
  _id: string;
  name: Roles;
}

export enum Roles {
  Admin = "Admin",
  Affiliate = "Affiliate",
  CustomerService = "Customer Service",
  SuperAdmin = "Super Admin"
}
