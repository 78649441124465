import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxFileDropModule } from "ngx-file-drop";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";

import { UploadDocumentsComponent } from "./upload-documents.component";

@NgModule({
  declarations: [UploadDocumentsComponent],
  exports: [UploadDocumentsComponent],
  imports: [CommonModule, NgxFileDropModule, MatExpansionModule, MatIconModule]
})
export class UploadDocumentsModule {}
