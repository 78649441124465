<h1 *ngIf="modalData.title" mat-dialog-title>{{ modalData.title }}</h1>
<div
  *ngIf="modalData.contentTemplate"
  mat-dialog-content
  class="mb-4"
>
  <ng-container
    [ngTemplateOutlet]="modalData.contentTemplate"
    [ngTemplateOutletContext]="{ $implicit: modalData.context }"
  ></ng-container>
</div>
<div *ngIf="modalData.actionsTemplate" mat-dialog-actions>
  <ng-container
    [ngTemplateOutlet]="modalData.actionsTemplate"
    [ngTemplateOutletContext]="{ $implicit: modalData.context }"
  ></ng-container>
</div>