import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from "rxjs";

import { SubmissionDetailsTabs, CoreService } from "../../../core/services/core.service";
import { Submission, SubmissionCampaigns } from "../../../core/types/submission.types";
import { SubmissionService } from "../../../core/services/submission.service";

interface SubmissionDetails {
  name: SubmissionDetailsTabs;
  id: string;
}
export interface SubmissionTabs {
  userInformation: SubmissionDetails;
}

@Component({
  selector: 'app-submission-details',
  templateUrl: './submission-details.component.html',
  styleUrls: ['./submission-details.component.scss']
})
export class SubmissionDetailsComponent implements OnInit {
  public tabs: SubmissionTabs = {
    userInformation: {
      name: SubmissionDetailsTabs.UserInformation,
      id: 'user-information'
    },
  };
  public tabsNames: typeof SubmissionDetailsTabs = SubmissionDetailsTabs;
  public currentTab: SubmissionDetailsTabs = SubmissionDetailsTabs.UserInformation;
  public tabComponentSelectorMap: Map<SubmissionDetailsTabs, string>;
  public submission?: Submission;
  public campaign: SubmissionCampaigns;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private coreService: CoreService,
    private submissionService: SubmissionService,
  ) {}

  public ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.campaign = history.state.campaign;
    });
    setTimeout(() => {
      this.findSubmission();
    });
  }

  public handleBackButtonClick() {
    this.router.navigate(['..']);
  }

  public keepTabsOrder(): number {
    return 0;
  }

  public handleTabClick(tab: SubmissionDetailsTabs, button: EventTarget | null): void {
    this.currentTab = tab;
    this.coreService.setSubmissionDetailsCurrentTab(tab);

    const buttonElement = button as HTMLButtonElement;
    buttonElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  private findSubmission(): void {
    const submissionId = this.route.snapshot.params['id'];

    this.coreService.setIsLoading(true);
    this.submissionService
      .getSubmission(submissionId, this.campaign)
      .pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((findSubmissionResponse) => {
        this.submission = findSubmissionResponse;
      });
  }
}
