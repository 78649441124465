<div class="container submissions-list">
  <app-card class="d-flex flex-column h-100 p-4">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="mb-0 text-capitalize">{{ tableHeader }}</h2>
      <button
        mat-raised-button
        color="primary"
        (click)="handleButtonClick()"
      >
        Export
      </button>
    </div>
    <mat-form-field> 
      <input matInput placeholder="Search Lead" #input>
    </mat-form-field>
    <div class="list-container">
      <span class="mt-3" *ngIf="!(isLoading | async) && !tableRowData.length">No data received</span>
      <app-table
        *ngIf="tableRowData.length"
        [clickableRow]="true"
        [columnsHeadersMap]="tableColumnsAndHeaders"
        [rowData]="tableRowData"
        [paginatorConfig]="paginatorConfig"
        (onRowClick)="handleTableRowClick($event)"
        (onPageClick)="handlePageClick($event)"
        (onSortClick)="handleSortClick($event)"
      ></app-table>
    </div>
  </app-card>
</div>

<ng-container>
  <ng-template #fileDownloadModalContentTemplate>
    <span class="fw-bold fs-3">Export Leads</span>
  </ng-template>
  <ng-template #fileDownloadModalActionsTemplate>
    <form [formGroup]="downloadFileForm">
      <div class="col-sm-6">
        <app-date-input
          formControlName="from"
          label="From Date"
          [maxDate]="maxExportDate"
        ></app-date-input>
      </div>
      <div class="col-sm-6">
        <app-date-input
          formControlName="to"
          label="To Date"
          [maxDate]="maxExportDate"
        ></app-date-input>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-3 mt-3">
        <button
        mat-button
        color="primary"
        (click)="handleModalClose()"
      >
        Cancel
      </button>
        <button
        type="button" 
        mat-raised-button
        color="primary"  
        [disabled]="downloadFileForm.invalid"      
        (click)="handleModalFileDownloadActionConfirmationButtonClick()"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-template>
</ng-container>
