import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs";

import { ModalComponent } from "../modal/modal.component";
import { CoreService } from "../../../core/services/core.service";
import { passwordValidator } from "../../directives/password/password.directive";
import { AuthService } from "../../../core/services/auth.service";
import { AuthenticatedUser } from "../../../core/types/auth.types";

@Component({
  selector: 'app-logged-user',
  templateUrl: './logged-user.component.html',
  styleUrls: ['./logged-user.component.scss']
})
export class LoggedUserComponent implements OnInit {
  @ViewChild('settingsModalContentTemplate')
  private settingsModalContentTemplate: TemplateRef<any>;
  @ViewChild('settingsModalActionsTemplate')
  private settingsModalActionsTemplate: TemplateRef<any>;
  public modalRef?: MatDialogRef<ModalComponent>;
  public hidePasswordInputsMap = new Map([
    ['oldPassword', true],
    ['newPassword', true],
    ['confirmPassword', true]
  ]);
  public profileForm;
  public emailForm;
  public securityForm;
  public activeTab = 'Profile';
  public loggedUser: AuthenticatedUser;
  public currentEmailForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private coreService: CoreService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService
  ) {
    this.profileForm = this.fb.nonNullable.group({
      name: ['', Validators.required]
    });
    this.emailForm = this.fb.nonNullable.group({
      address: this.fb.nonNullable.group({
        email: ['', [Validators.required, Validators.email]]
      }),
      validation: this.fb.nonNullable.group({
        code: ['', Validators.required]
      })
    });
    this.securityForm = this.fb.nonNullable.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, passwordValidator()]],
      confirmPassword: ['', [Validators.required, passwordValidator(true, 'newPassword')]]
    });
    this.currentEmailForm = this.emailForm.controls.address;
  }

  public get disableContinueButton(): boolean {
    if (this.activeTab === 'Profile' && this.profileForm.invalid) {
      return true;
    }

    if (this.activeTab === 'Security' && this.securityForm.invalid)  {
      return true;
    }

    if (this.activeTab === 'Email' && this.emailForm.controls['address'].invalid) {
      return true;
    }

    return false;
  }

  public ngOnInit(): void {
    this.loggedUser = this.authService.getLoggedUser();

    this.initializeForms();
  }

  public handleSettingsButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      autoFocus: 'dialog',
      data: {
        title: 'Settings',
        contentTemplate: this.settingsModalContentTemplate,
        actionsTemplate: this.settingsModalActionsTemplate
      },
      restoreFocus: false
    });
  }

  public async handleLogoutButtonClick(): Promise<void> {
    this.coreService.setIsLoading(true);

    try {
      this.authService.logout();
      this.router.navigate(['/auth']);
    } finally {
      this.coreService.setIsLoading(false);
    }
  }

  public handleTabChange(event: MatTabChangeEvent): void {
    this.activeTab = event.tab.textLabel;
  }

  public handleModalCancelButtonClick(): void {
    this.closeModal();
    this.securityForm.reset();

    if (this.profileForm.invalid || this.emailForm.controls.address.invalid) {
      this.initializeForms();
    }
  }

  public handleModalConfirmButtonClick(): void {
    this.coreService.setIsLoading(true);

    switch (this.activeTab) {
      case 'Profile':
        this.handleProfileFormSubmit();
        break;
      case 'Email':
        this.handleEmailFormSubmit();
        break;
      case 'Security':
        this.handleSecurityFormSubmit();
        break;
      default:
        break;
    }
  }

  public togglePasswordInput(event: MouseEvent, controlName: keyof typeof this.securityForm.controls): void {
    const currentState = this.hidePasswordInputsMap.get(controlName);

    event.stopPropagation();

    this.hidePasswordInputsMap.set(controlName, !currentState);
  }

  public getErrorMessage(form: FormGroup, controlName: string, label: string): string {
    let errorMessage = '';
    const control = form.controls[controlName];

    if (control.hasError('required')) {
      errorMessage = `${label} is required`;
    } else if (control.hasError('email')) {
      errorMessage = `${label} must be a valid email`;
    } else if (control.hasError('passwordInvalid')) {
      errorMessage = control.errors!['passwordInvalid'][0];
    }

    return errorMessage;
  }

  public closeModal(): void {
    this.modalRef!.close();
    this.modalRef = undefined;
  }

  private initializeForms(): void {
    this.profileForm.patchValue({ name: this.loggedUser.attributes.name });
    this.emailForm.controls.address.patchValue({ email: this.loggedUser.attributes.email });
  }

  private handleProfileFormSubmit(): void {
    this.profileForm.markAllAsTouched();

    if (this.profileForm.valid) {
      this.authService
        .updateUser(this.profileForm.value)
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe({
          complete: () => {
            this.closeModal();
            this.toastrService.success(
              'Profile successfully updated',
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );

            this.loggedUser.attributes.name = this.profileForm.controls.name.value;
          },
          error: (error) => {
            this.toastrService.error(
              error,
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
          }
        });
    } else {
      this.coreService.setIsLoading(false);
    }
  }

  private async handleEmailFormSubmit(): Promise<void> {
    this.currentEmailForm.markAllAsTouched();

    if (this.currentEmailForm.valid) {
      if (this.currentEmailForm === this.emailForm.controls.address) {
        this.authService
          .updateUser(this.emailForm.controls.address.value)
          .pipe(
            finalize(() => {
              this.coreService.setIsLoading(false);
            })
          )
          .subscribe({
            complete: () => {
              this.toastrService.success(
                'A verification code was sent to your email',
                undefined,
                { positionClass: 'toast-custom-bottom-center' }
              );

              this.currentEmailForm = this.emailForm.controls.validation;
            },
            error: (error) => {
              this.toastrService.error(
                error,
                undefined,
                { positionClass: 'toast-custom-bottom-center' }
              );
            }
          });
      } else {
        this.authService.verifyUserAttribute(
          'email',
          this.emailForm.controls.validation.value.code!
        )
        .pipe(
          finalize(() => {
            this.coreService.setIsLoading(false);
          })
        )
        .subscribe({
          complete: () => {
            this.closeModal();
            this.emailForm.controls.validation.reset();
            this.toastrService.success(
              'Email successfully updated',
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
            
            this.loggedUser.attributes.email = this.emailForm.controls.address.value.email!;
            this.currentEmailForm = this.emailForm.controls.address;
          },
          error: (error) => {
            this.toastrService.error(
              error,
              undefined,
              { positionClass: 'toast-custom-bottom-center' }
            );
          }
        });
      }
    } else {
      this.coreService.setIsLoading(false);
    }
  }

  private async handleSecurityFormSubmit(): Promise<void> {
    this.securityForm.markAllAsTouched();

    if (this.securityForm.valid) {
      try {
        await this.authService.changePassword({
          oldPassword: this.securityForm.value.oldPassword!,
          newPassword: this.securityForm.value.newPassword!
        });

        this.closeModal();
        this.securityForm.reset();
        this.toastrService.success(
          'Password successfully updated',
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
      } catch (error: any) {
        this.toastrService.error(
          error.message,
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );        
      } finally {
        this.coreService.setIsLoading(false);
      }
    } else {
      this.coreService.setIsLoading(false);
    }
  }
}
