import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';

import { CoreService } from './core/services/core.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isLoading: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private coreService: CoreService,
    private renderer: Renderer2
  ) {
    this.isLoading = this.coreService.isLoading$;
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.isLoading.subscribe((isLoading) => {
        if (isLoading) {
          this.renderer.addClass(this.document.body, 'overflow-hidden');
        } else {
          this.renderer.removeClass(this.document.body, 'overflow-hidden');
        }
      });
    });
  }
}
