import { AfterViewInit, Component, ElementRef, Input, OnChanges, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { Observable, debounceTime, distinctUntilChanged, finalize, fromEvent, lastValueFrom, tap } from "rxjs";
import { format } from "date-fns";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { CoreService } from "../../../core/services/core.service";
import { SubmissionService } from "../../../core/services/submission.service";
import { PaginatorConfigOptions, RowData, SortConfigOptions } from "../table/table.component";
import {
  GetDownloadSubmissionsParams,
  GetSubmissionsParams,
  Submission,
  SubmissionsOrderBy,
  SubmissionsSortColumns
} from "../../../core/types/submission.types";
import { ModalComponent } from "../modal/modal.component";

interface TableRowData {
  _id: string;
  name: string;
  requestedAmount: string;
  createdAt: string;
  cellPhone: string;
  email: string;
}

@Component({
  selector: 'app-submissions-list',
  templateUrl: './submissions-list.component.html',
  styleUrls: ['./submissions-list.component.scss']
})
export class SubmissionsListComponent implements OnChanges, AfterViewInit {
  @Input() public tableHeader: string;
  @Input() public campaign: string;
  @ViewChild('fileDownloadModalContentTemplate')
  private fileDownloadModalContentTemplate: TemplateRef<any>;
  @ViewChild('fileDownloadModalActionsTemplate')
  private fileDownloadModalActionsTemplate: TemplateRef<any>;

  @ViewChild('input') private input: ElementRef;
  public isLoading: Observable<boolean>;
  public paginatorConfig?: PaginatorConfigOptions = {
    length: 0,
    pageSize: 10,
    pageIndex: 0,
    pageSizeOptions: [5, 10, 25],
    showFirstLastButtons: true
  };
  public sortConfig: SortConfigOptions = {
    orderBy: SubmissionsOrderBy.Desc,
    orderByColumn: SubmissionsSortColumns.Id
  };
  public submissions: Submission[] = [];
  public modalRef?: MatDialogRef<ModalComponent>;
  public tableColumnsAndHeaders: Map<string, string> = new Map([
    ['_id', '#'],
    ['name', 'Name'],
    ['email', 'Email'],
    ['dataSource', 'Source'],
    ['requestedAmount', 'Amount'],
    ['createdAt', 'Date Created'],
    ['cellPhone', 'Mobile Phone'],
  ]);
  public downloadFileForm: FormGroup;
  public tableRowData: TableRowData[] = [];
  public disabledSortColumns: string[] = [];

  constructor(
    private coreService: CoreService,
    private submissionService: SubmissionService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute
  ) {
    this.isLoading = this.coreService.isLoading$;
    this.downloadFileForm = this.fb.nonNullable.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
    });
  }

  public get maxExportDate(): Date {
    const maxExportDate = new Date();
    return maxExportDate;
  }

  public ngOnChanges(): void {
    setTimeout(() => {
      this.getSubmissions({
        orderBy: this.sortConfig.orderBy,
        campaign: this.campaign
      });
    });
  }

  public ngAfterViewInit(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
          debounceTime(250),
          distinctUntilChanged(),
          tap(() => {
            this.getSubmissions({
              pageSize: 0,
              orderByColumn: this.sortConfig.orderByColumn,
              orderBy: this.sortConfig.orderBy,
              search: this.input.nativeElement.value,
              campaign: this.campaign
            });
          })
      )
      .subscribe();
  }

  public handleTableRowClick(row: RowData): void {
    const navigateTo = ['details', row['_id']];
    this.router.navigate(navigateTo, { relativeTo: this.route, state: { campaign: this.campaign } });
  }

  public handleButtonClick(): void {
    this.modalRef = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: true,
      autoFocus: 'dialog',
      data: {
        contentTemplate: this.fileDownloadModalContentTemplate,
        actionsTemplate: this.fileDownloadModalActionsTemplate,
      },
      restoreFocus: false
    });
  }

  public handleModalClose(): void {
    this.modalRef!.close();
    this.downloadFileForm.reset();
  }

  public async handleModalFileDownloadActionConfirmationButtonClick(): Promise<void> {
    this.downloadFileForm.markAllAsTouched();

    if (this.downloadFileForm.valid) {
      try {
        this.coreService.setIsLoading(true);
        const submissionParams = this.downloadFileForm.value as GetDownloadSubmissionsParams;
  
        const blob = await lastValueFrom(this.submissionService.downloadFile(submissionParams, this.campaign));

        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        const fileName = `submissions-from-${submissionParams.from}-to-${submissionParams.to}`;
    
        downloadLink.href = url;
        downloadLink.download = `${this.campaign}_${fileName}.csv`;
        downloadLink.click();
        window.URL.revokeObjectURL(url);

        this.toastrService.success(
          'File Downloaded Successfully', 
          undefined,
          { positionClass: 'toast-custom-bottom-center' }
        );
  
        this.modalRef!.close();
        this.downloadFileForm.reset();
      } finally {
        this.coreService.setIsLoading(false);
      }
    }
  }

  public handlePageClick(event: PageEvent): void {
    this.getSubmissions({
      page: event.pageIndex,
      pageSize: event.pageSize,
      orderByColumn: this.sortConfig.orderByColumn,
      orderBy: this.sortConfig.orderBy,
      campaign: this.campaign
    });
  }

  public handleSortClick(sort: Sort): void {
    this.getSubmissions({
      page: 0,
      pageSize: this?.paginatorConfig?.pageSize!,
      orderByColumn: sort.active,
      orderBy: sort.direction,
      campaign: this.campaign
    });
  }

  public getSubmissions(params?: GetSubmissionsParams): void {
    const search = params?.search?.replace(/,/g, '');

    this.coreService.setIsLoading(true);
    this.submissionService
      .getSubmissions({
        pageSize: params?.pageSize,
        page: params?.page,
        search: search,
        orderByColumn: params?.orderByColumn,
        orderBy: params?.orderBy,
        campaign: params?.campaign!
      }).pipe(
        finalize(() => {
          this.coreService.setIsLoading(false);
        })
      )
      .subscribe((getSbmissionsByStatusResponse) => {
        const { submissions, ...pageData } = getSbmissionsByStatusResponse;
        this.submissions = submissions;
        if (!!this.paginatorConfig) {
          this.paginatorConfig.length = pageData.totalItems;
          this.paginatorConfig.pageIndex = pageData.page;
          this.paginatorConfig.pageSize = pageData.itemsPerPage;
          this.sortConfig.orderBy = pageData.orderBy;
          this.sortConfig.orderByColumn = pageData.orderByColumn;
        }
        this.prepareTableData();
      })
  }

  private prepareTableData(): void {
    this.tableRowData = this.submissions.map((submission) => {
      const createdAtDate = new Date(submission.createdAt);
      const formattedDate = format(new Date(createdAtDate), 'MM/dd/yyyy');
      const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

      return {
        _id: submission._id,
        createdAt: formattedDate,
        email: submission.email,
        dataSource: submission.dataSource || 'Landing Page',
        name: `${submission.firstName} ${submission.lastName}`,
        cellPhone: submission.cellPhone,
        requestedAmount: submission.loanAmount ? currencyFormatter.format(submission.loanAmount) : 'N/A',
      };
    });
  }
}
