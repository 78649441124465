import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../../core/services/auth.service";
import { AuthenticatedUser } from "../../core/types/auth.types";
import { Affiliate } from "../../core/types/affiliate.types";
import { Roles } from "../../core/types/role.type";

@Component({
  selector: 'app-affiliate',
  templateUrl: 'affiliate.component.html',
  styleUrls: ['./affiliate.component.scss']
})
export class AffiliateComponent implements OnInit {
  public user: AuthenticatedUser;
  public affiliate: Affiliate;

  constructor(private authService: AuthService, private router: Router) {}

  public ngOnInit(): void {
    this.user = this.authService.getLoggedUser();


    if (this.user.attributes["custom:role"] !== Roles.Affiliate) {
      this.authService.logout();
      this.router.navigate(['/auth']);
    }
  }
}
