import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Amplify } from 'aws-amplify';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AuthModule } from './features/auth/auth.module';
import { AffiliateModule } from './features/affiliate/affiliate.module';
import awsconfig from '../aws-exports.js';

Amplify.configure(awsconfig);
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AmplifyAuthenticatorModule,
    CommonModule,
    CoreModule,
    AppRoutingModule,
    FeaturesModule,
    SharedModule,
    AuthModule,
    AffiliateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot()
  ],
  providers: [provideNgxMask()]
})
export class AppModule {}
