<mat-form-field
  appearance="outline"
  [ngClass]="{
    'mat-form-field-invalid': control.touched && control.invalid
  }"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    bsDatepicker
    autocomplete="none"
    #datepickerInput="bsDatepicker"
    [bsConfig]="config"
    [maxDate]="maxDate"
    [bsValue]="value"
    (bsValueChange)="handleDateChange($event)"
    (blur)="onTouched()"
  />
  <button
    mat-icon-button
    matSuffix
    type="button"
    (click)="datepickerInput.isOpen ? datepickerInput.hide() : datepickerInput.show()"
  >
    <mat-icon svgIcon=calendar></mat-icon>
  </button>
  <mat-hint class="pb-2 text-danger fw-bold" *ngIf="control.touched && control.invalid">
    {{ label }} is required
  </mat-hint>
</mat-form-field>