<canvas
  *ngIf="chartData?.datasets?.length"
  baseChart
  type="line"
  [data]="chartData"
  [options]="chartOptions"
></canvas>

<span class="mt-3" *ngIf="!(isLoading | async) && !chartData?.datasets?.length">
  No data received
</span>