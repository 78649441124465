import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

import { SubmissionDetailsComponent } from "./submission-details.component";
import { ConsumerInformationTabComponent } from "./consumer-information-tab/consumer-information-tab.component";
import { CardModule } from "../card/card.module";

@NgModule({
  declarations: [SubmissionDetailsComponent, ConsumerInformationTabComponent],
  exports: [SubmissionDetailsComponent],
  imports: [CommonModule, NgxMaskDirective, NgxMaskPipe, CardModule],
  providers: [provideNgxMask()]
})
export class SubmissionDetailsModule {}
